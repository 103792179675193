<template>
  <section class="Partners">
    <div class="Container">
      <div class="Partners-TitleBox">
        <h3 class="Title-h3 Partners-Title">
          Our Partners
        </h3>
        <div class="Partners-Subtitle">
          We partner with our clients to understand the why behind your business, the goals you aspire to and impact
          this will have on your customers and business
        </div>
      </div>
      <div class="Partners-List">
        <div class="Partners-Item"
             v-for="(info, index) in listInfo"
             :key="index"
        >
          <img src="../assets/images/quote.svg" alt="quote" class="Partners-Quote">
          <p class="Text Partners-Text" v-html="info.text"></p>
          <div class="Partners-Box">
            <img :src="require(`@/assets/images/${info.logo}`)" alt="logo" class="Partners-Logo">
            <div class="Partners-Company">
              <div class="Partners-Name">{{ info.name }}</div>
              <div class="Partners-Position">{{ info.position }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Partners",
  data() {
    return {
      listInfo: [
        {
          text: "Overall a great experience. Communication was done professionally. My questions were answered promptly.",
          logo: "pennymac.png",
          name: "SOPHIA MAGALLAN",
          position: ""
        },
        {
          text: "A great experience from start to finish. Communication was clear and everything progressed smoothly and quickly. Even closed several days prior to the originally anticipated date. Overall a great experience and I couldn't be more pleased!",
          logo: "zesty.png",
          name: "sharon grainger",
          position: ""
        },
        {
          text: "I would rate our experience with Digitalius as a 5 out of 5, everything proceeded in a smooth and faster than expected manner.",
          logo: "collegewise.png",
          name: "Vanessa Daves-Poarch",
          position: "Marketing Associate"
        },
        {
          text: "This is a great company to do business with. They are informative and helpful and very friendly no matter who you talk to. I highly recommend them.",
          logo: "gett.png",
          name: "angelica garcia",
          position: ""
        },
        {
          text: "Everyone I dealt with in Digitalius was super nice and helpful. They were able to answer all my questions and I asked a lot of them. The closing was ahead of schedule and we signed our paperwork and saved money on our project. Great job!",
          logo: "bpisport.png",
          name: "clarence robinson",
          position: "CEO"
        },
        {
          text: "The customer service was awesome, I did not have to call as I was updated every step of the process which took away a lot of stress.",
          logo: "regus.png",
          name: "WALTER PRIEST ",
          position: ""
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.Partners {
  padding: 150px 0 100px;

  @media (min-width: $screen-l) {
    padding: 200px 0 150px;
  }

  &-TitleBox {
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (min-width: $screen-l) {
      flex-direction: row;
      margin-bottom: 68px;
      padding-left: 80px;
    }
  }

  &-Title {
    margin-bottom: 40px;
    white-space: nowrap;
    color: var(--color-text-main2);
    @media (min-width: $screen-l) {
      margin-right: 33px;
      margin-bottom: 0;
    }
  }

  &-Subtitle {
    margin-bottom: 60px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;

    @media (min-width: $screen-l) {
      margin-bottom: 0;
      padding-right: 30px;
      padding-left: 13px;
      position: relative;
      text-align: left;
      &:before {
        content: '';
        position: absolute;
        width: 3px;
        height: 32px;
        background-color: var(--color-text-yellow-dark);
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
      }
    }
  }

  &-List {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 30px 0;
    justify-content: center;

    @media (min-width: $screen-s) {
      grid-template-columns: 540px;
    }

    @media (min-width: $screen-xl) {
      grid-template-columns: repeat(2, 540px);
      grid-gap: 30px;
    }
  }

  &-Item {
    padding: 45px 36px 30px;
    box-shadow: 0px -2px 10px rgba(29, 29, 37, 0.15);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
  }

  &-Quote {
    margin-bottom: 14px;
  }

  &-Text {
    margin-bottom: 18px;
  }

  &-Box {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: $screen-s) {
      flex-direction: row;
    }
  }

  &-Logo {
    margin-bottom: 30px;
    @media (min-width: $screen-s) {
      margin-right: 30px;
      margin-bottom: 0;
    }
  }

  &-Company {
    padding-left: 23px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 23px;
      background-color: rgba(178, 179, 190, .3);
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
    }
  }

  &-Name {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.5;
    text-transform: capitalize;
  }

  &-Position {
    font-size: 14px;
    line-height: 1.5;
    color: var(--color-text-main2);
  }
}
</style>