<template>
  <Partners />
</template>

<script>
import Partners from "@/components/Partners";
export default {
  name: "PartnersPage",
  components: {Partners}
}
</script>

<style scoped>

</style>